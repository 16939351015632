import { createRouter, createWebHashHistory } from "vue-router";
// import Home from '../views/home/Home.vue'
// createWebHistory(import.meta.env.BASE_URL)
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../views/Home.vue"),
    },
    // {
    //   path: "/",
    //   name: "popEchars",
    //   component: () => import("../components/popEchars.vue"),
    // },
    {
      path: "/slider",
      name: "slider",
      component: () => import("../components/slider.vue"),
    },
    {
      path: "/zoombar",
      name: "zoomBar",
      component: () => import("../components/zoomBar.vue"),
    },
    {
      path: "/pop",
      name: "pop",
      component: () => import("../components/pop.vue"),
    },
    {
      path: "/eventhtml",
      name: "eventhtml",
      component: () => import("../components/eventHtml"),
    },
    {
      path: "/goHtml",
      name: "goHtml",
      component: () => import("../components/goHtml"),
    },
    {
      path: "/search",
      name: "search",
      component: () => import("../views/search/Search"),
    },
  ],
});

export default router;
