import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import { ElPopover, Autocomplete } from "element-plus";
import "element-plus/dist/index.css";
import router from "./router";

const app = createApp(App);

app.use(ElementPlus);
app.use(router);
app.use(ElPopover);
app.use(Autocomplete);

app.mount("#app");

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份

    "d+": this.getDate(), //日

    "h+": this.getHours(), //小时

    "m+": this.getMinutes(), //分

    "s+": this.getSeconds(), //秒

    "q+": Math.floor((this.getMonth() + 3) / 3), //季度

    S: this.getMilliseconds(), //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }

  return fmt;
};
