<template>
  <router-view />
</template>

<script lang="js">
import {  defineComponent   } from "vue";

export default defineComponent({
  name: "PaasDemo",
  components:{},
  setup() {
      return { };
    },
});
</script>

<style></style>
